<template>
  <div>
    <b-card>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-3 col-md-4 col-sm-12 d-flex align-items-center">
            <div>
              <h5 class="text-center">Filter By Account Name :</h5>
            </div>
          </div>
          <div class="col-lg-5 col-md-8 col-sm-12 ">
            <div class="d-flex align-items-center">
              <b-form-group class="w-100">
                <b-input-group size="md">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center">
            <b-form-group>
              <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-success"
                to="/myprofile/accounts/creation">
                Add an Account <feather-icon icon="PlusIcon" size="12" />
              </b-button>
            </b-form-group>

          </div>
        </div>
      </div>
    </b-card>
    <b-card>
      <b-row>
        <b-modal id="modal-danger" ok-variant="danger" ok-title="Confirm" modal-class="modal-danger" centered
          title="Delete" @ok="deleteDomain">
          <b-card-text>
            Are you sure you want to delete this account?
          </b-card-text>
        </b-modal>
        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
            :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
            <template #cell(action)="data">
              <b-button-group>
                <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="flat-warning" class="btn-icon rounded-circle"
                  v-b-tooltip.hover title="Update" @click="editDomain(data.item.id)">
                  <feather-icon icon="EditIcon" size="20" />
                </b-button>

                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle"
                  v-b-tooltip.hover title="Delete" @click="setDeleteId(data.item.id)">
                  <feather-icon icon="Trash2Icon" size="20" />
                </b-button>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle"
                  v-b-tooltip.hover title="Show Apps" :to="`/myprofile/account/${data.item.id}/apps/creation/`">
                  <feather-icon icon="SearchIcon" size="20" />
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </b-col>
        <b-col cols="6">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-25" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
            class="my-0" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BContainer,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BModal,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import store from "@/store";
import router from "@/router";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";

export default {
  components: {
    BTable,
    BAvatar,
    VBTooltip,
    BCard,
    BImg,
    BButtonGroup,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    VBModal,
    BCardText,
    BContainer,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      accountToDelete: null,
      account_name: null,
      account_id: null,
      account_link: null,
      perPage: 10,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      token: null,
      filter: null,
      filterOn: [],
      // url: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "account_name",
          label: "Account Name",
        },
        {
          key: "account_link",
          label: "Account Link",
        },
        "Action",
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.token = JSON.parse(localStorage.getItem("accessToken"));

    this.totalRows = this.items.length;
    this.showAllAccounts();
  },
  created() { },
  methods: {
    setDeleteId(id) {
      this.accountToDelete = id;
      this.$root.$emit("bv::show::modal", "modal-danger");
    },
    deleteDomain() {
      var id = this.accountToDelete;
      axios
        .post("/delete-account", {
          id: id,
          user_token: this.token

        })
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            this.showAllAccounts();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editDomain: (id) => {
      store
        .dispatch("appsManager/setAccountId", id)
        .then((response) => {
          router.push({ name: "accounts-edit" });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    showAllAccounts() {
      this.$store
        .dispatch("appsManager/getAllAccounts")
        .then(({ data }) => {
          this.items = this.addIndexToTable(data.accounts);
          this.totalRows = this.items.length;
        })
        .catch(console.error);
    },

    addIndexToTable(table) {
      const t = table.map((item, index) => {
        item.index = index + 1;

        return item;
      });

      return t;
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>